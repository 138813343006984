<!--
 * @Author: Dyf
 * @Date: 2023-11-06 18:12:33
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-11-29 16:19:05
 * @Description: 课程表
-->
<style lang="scss" scoped>
.school-timetable {
  position: relative;
  @include innerPage($p: 24px 0 0);
  @include pageTitle(0);
  .data-inner {
    width: 100%;
    height: calc(100% - 1em - 56px);
    overflow: hidden;
    overflow-y: auto;
    margin-top: 24px;
  }
  &--inner {
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    border-radius: 10px;
    overflow: hidden;

    .inner-head {
      width: 100%;
      height: 74px;
      font-size: 16px;
      background: #eff1fa;
      border-radius: 10px 10px 0 0;
      @include flexBox;

      &--col {
        width: 12.5%;
        height: 100%;
        box-sizing: border-box;
        border: 1px solid #e6e8f1;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &:first-child {
          border-top-left-radius: 10px;
        }

        &:last-child {
          border-top-right-radius: 10px;
        }
      }
    }

    .timetable {
      @include flexBox;
      &-row {
        width: 12.5%;
        position: relative;
        @include flexBox;
        flex-direction: column;
        &:last-child {
          .timetable-col:first-child {
            border-bottom-left-radius: 10px;
          }

          .timetable-col:nth-child(8) {
            border-bottom-right-radius: 10px;
          }
        }
        &.time {
          font-family: "PingFang SC Medium";
          font-size: 16px;
          line-height: 24px;
        }
        &:not(.time) .timetable-col {
          &:not(.empty):not(.cur):hover {
            background: rgba($color: #bfedde, $alpha: 1);
            cursor: pointer;
          }
          &.cur {
            background: rgba($color: #e0d9f4, $alpha: 1);
            cursor: pointer;
          }
        }
      }

      &-col {
        width: 100%;
        height: 136px;
        box-sizing: border-box;
        padding: 0 20px;
        color: #1a1a1a;
        border: 1px solid #e6e8f1;
        background: #f5f7fe;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: relative;
        text-align: center;

        p {
          white-space: nowrap;
          line-height: 24px;

          .bold {
            font-size: 16px;
          }

          .light {
            margin-left: 9px;
            font-size: 14px;
          }
        }

        .col-btns {
          width: 100%;
          position: absolute;
          bottom: 14px;
          left: 0;
          opacity: 0;
          @include flexBox(space-between);

          .el-button {
            padding: 0 20px;
          }

          .success {
            margin-left: 0;
          }
        }

        &:hover .col-btns {
          opacity: 1;
        }
      }
    }
  }
  .lesson-info {
    position: relative;
    min-height: 280px;
    box-sizing: border-box;
    padding: 24px;
    .info-data {
      .teacher {
        height: 86px;
        display: flex;
        margin-top: -14px;
        align-items: center;
        box-sizing: border-box;
        padding: 10px 6px 20px;
        border-bottom: 1px solid #e9e9e9;

        .el-avatar {
          border: 1px solid #fff;
          margin-right: 10px;
        }
        b {
          font-size: 18px;
        }
      }
      p {
        line-height: 36px;
        margin-top: 10px;
        color: #555;
        box-sizing: border-box;
        span.label {
          width: 64px;
          display: inline-block;
          text-align: right;
          margin-right: 8px;
          color: #333;
        }
        a {
          color: #2ac293;
        }
      }
    }
    .no-data {
      &--empty {
        flex-direction: column;
        @include flexBox(center);

        img {
          width: 160px;
        }

        p {
          line-height: 24px;
          font-size: 12px;
          margin-top: 10px;
        }
      }
    }
  }
}
::v-deep .el-dialog__body {
  width: 100%;
}

@media screen and (max-width: 1200px) {
  .school-timetable{
    @include innerPage($p: 18px 0 0);
  }
  .school-timetable--inner {
    .timetable-row {
      &.time {
        font-size: 14px;
      }
      &:not(.time) .timetable-col {
        padding: 0 8px;
        p{
          .bold{
            font-size: 14px;
          }
          .light{
            font-size: 12px;
            margin-left: 6px;
          }
        }
      }
    }
  }
}
</style>
<template>
  <section class="school-timetable">
    <div class="page-title">
      <h3>课程表</h3>
      <div class="lesson-set">
        <el-date-picker
          v-model="date"
          type="date"
          placeholder="选择日期"
          @change="getTimeTable"
        />
      </div>
    </div>
    <div class="data-inner">
      <div class="school-timetable--inner" ref="timetable">
        <ul class="inner-head pf_bold">
          <li class="inner-head--col">时间</li>
          <li
            class="inner-head--col"
            v-for="(item, index) in timetable"
            :key="index"
          >
            {{ week[index] }}
          </li>
        </ul>
        <div class="timetable">
          <!-- 时间 -->
          <ul class="timetable-row time">
            <li
              class="timetable-col"
              v-for="(item, index) in timetable[0].course"
              :key="index"
            >
              <p>
                {{ item.sccla_sch_tim_starttime | formatTime("HH:mm") }}~{{
                  item.sccla_sch_tim_endtime | formatTime("HH:mm")
                }}
              </p>
            </li>
          </ul>
          <!-- 课程 -->
          <ul
            class="timetable-row"
            v-for="(item, index) in timetable"
            :key="index"
          >
            <li
              class="timetable-col"
              :class="{ empty: !citem.sysub_name }"
              v-for="(citem, cindex) in item.course"
              :key="cindex"
              @click="getLesson(index, cindex)"
            >
              <div class="line-text--1st">
                <p class="bold">{{ citem.sysub_name }}</p>
                <p class="light">{{ citem.teuse_name }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <el-dialog
        title="课程信息"
        :visible.sync="info_dialog"
        width="450px"
        @close="hideInfo"
      >
        <div class="lesson-info" v-loading="lesson_load">
          <div
            class="info-data"
            v-show="!$isEmpty(lesson_info) && !lesson_load"
          >
            <div class="teacher">
              <el-avatar :size="56" :src="formatfile(lesson_info.teuse_image)">
                <img src="@assets/images/empty_avatar.png" />
              </el-avatar>
              <b>{{ lesson_info.teuse_name }}</b>
            </div>
            <p><span class="label">科目:</span> {{ lesson_info.sysub_name }}</p>
            <p>
              <span class="label">课程状态:</span>
              <el-tag
                :type="
                  (lesson_info.state == 1 && 'danger') ||
                  (lesson_info.state == 2 && 'success') ||
                  'info'
                "
              >
                {{
                  (lesson_info.state == 1 && "待开始") ||
                  (lesson_info.state == 2 && "已开始") ||
                  "已结束"
                }}
              </el-tag>
            </p>
            <p>
              <span class="label">课程内容:</span>
              {{ lesson_info.sccou_cha_title }}
            </p>
            <p>
              <span class="label">上课时间:</span>
              {{ lesson_info.date | formatTime("YYYY年MM月DD日") }}
              {{ lesson_info.timeData }} (周{{
                lesson_info.date | formatTime("d")
              }})
            </p>
            <p v-if="lesson_info.state == 3">
              <span class="label">课程回放:</span>
              <router-link
                :to="{
                  name: 'SCHOOLTIMETABLE_PLAY',
                  params: { id: lesson_info.tecla_id },
                }"
                
              >
                查看
              </router-link>
            </p>
          </div>
          <div
            class="no-data--empty"
            v-show="$isEmpty(lesson_info) && !lesson_load"
          >
            <img src="@assets/images/no-data3.png" alt />
            <p>暂无课程信息哦~</p>
          </div>
        </div>
      </el-dialog>
    </div>
  </section>
</template>

<script>
import { get_stu_claschedule, get_courseware_data } from "@api/home";
import { $downloadFile } from "@api/common";
import { formatFile, formatFileType } from "@utils";
export default {
  computed: {
    formatfile() {
      return function (url) {
        return formatFile(url);
      };
    },
    fileType() {
      return function (val) {
        return formatFileType(val);
      };
    },
    curTime() {
      return (index, cindex) => {
        if (Object.keys(this.lesson_info).length == 0) return false;
        let timetable = this.timetable[index];
        let course = timetable.course;
        let lesson = course[cindex];
        return (
          lesson.sysub_name &&
          lesson.tecla_id == this.lesson_info.tecla_id &&
          lesson.sccla_sch_tim_starttime ==
            this.lesson_info.sccla_sch_tim_starttime &&
          timetable.date == this.lesson_info.date
        );
      };
    },
  },
  data() {
    return {
      week: ["一", "二", "三", "四", "五", "六", "日"],
      date: new Date(), // 当前课程数据绑定日期
      timetable: [{ course: [] }], // 当前周内课程表
      lesson_info: {}, // 当前选中课程数据详情
      lesson_load: false,
      info_dialog: false,
    };
  },
  created() {
    this.getTimeTable();
  },
  methods: {
    /** 获取当前周内课程表 */
    async getTimeTable() {
      let date = this.date.getTime() / 1000;
      let { data } = await get_stu_claschedule({ date: parseInt(date) });
      this.timetable = [...data];
      this.lesson_info = {};
    },
    /** 获取选中课程详情 */
    async getLesson(index, cindex) {
      let timetable = this.timetable[index];
      let course = timetable.course;
      let lesson = course[cindex];
      this.info_dialog = true;
      if (!lesson.tecla_id) return;
      this.lesson_load = true;
      let { data } = await get_courseware_data({ tecla_id: lesson.tecla_id });
      this.lesson_load = false;
      data = (this.$isEmpty(data) && {}) || data;
      this.lesson_info = {
        ...data,
        date: timetable.date,
        state: lesson.state
      };
      console.log(this.lesson_info)
    },
    /** 下载课件 */
    async handleDownload(url, index) {
      this.lesson_info.coursewareData.btnload = true;
      let { data } = await $downloadFile(url);
      this.lesson_info.coursewareData.btnload = false;
      if (data.url) {
        this.$fileDownload(data.url);
      }
    },
    hideInfo() {
      this.info_dialog = false;
      this.lesson_info = {};
    },
  },
};
</script>